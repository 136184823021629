import Cookies from 'js-cookie'

const DEVICE_KEY = 'deviceKey'
const DEVICE_GROUP_KEY = 'deviceGroupKey'
const RANDOM_PASSWORD_KEY = 'randomPasswordKey'

export const overwriteDeviceKey = (user) => {
  if (!user) return

  // set(overwrite) device keys in 30 days
  const deviceKeyPrefix = `${user.keyPrefix}.${user.username}.${DEVICE_KEY}`
  const deviceGroupPrefix = `${user.keyPrefix}.${user.username}.${DEVICE_GROUP_KEY}`
  const randomPasswordPrefix = `${user.keyPrefix}.${user.username}.${RANDOM_PASSWORD_KEY}`
  const randomPassword = Cookies.get(randomPasswordPrefix)
  Cookies.set(deviceKeyPrefix, user.deviceKey, { expires: 30, path: '/' })
  Cookies.set(deviceGroupPrefix, user.deviceGroupKey, { expires: 30, path: '/' })
  Cookies.set(randomPasswordPrefix, randomPassword, { expires: 30, path: '/' })
}
