import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { Button, Typography, DialogActions, DialogContent, FormControl, InputLabel } from '@mui/material'
import { useAlert } from '@hooks'
import { FormInput, StyledForm } from '@components/authentication/mfa/styles'
import { logger } from '@utils'

export const VerifyTOTPForm = ({ user, handleClose, handleSuccessLogin }) => {
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const alert = useAlert()
  const handleCodeChange = (e) => {
    setError('') // clear out the error message
    const value = e.target.value.replace(/[^0-9]/g, '')
    if (value.length <= 6) setCode(value)
  }

  const handleVerifyTOTP = async (e) => {
    if (loading) return
    setLoading(true)
    e.preventDefault()
    try {
      const response = await Auth.verifyTotpToken(user, code)
      if (response.Status === 'SUCCESS') {
        await Auth.setPreferredMFA(user, 'TOTP')
        alert({ status: 'success', message: 'Successful login, welcome!' })
        handleSuccessLogin()
      }
    } catch (error) {
      setLoading(false)
      const newError = new Error(`Setup TOTP error - ${user.attributes.email} - ${error.message}`)
      logger.error(newError)
      setError('The code is incorrect or expired. Verify the code and try again.')
    }
  }

  return (
    <StyledForm onSubmit={handleVerifyTOTP}>
      <DialogContent>
        <Typography variant='body1' data-cy='verificationText'>
          Enter the six-digit verification code you see in the authenticator
          app.
        </Typography>
        <FormControl variant='standard' margin='dense'>
          <InputLabel shrink data-cy='headerText'>Verification Code</InputLabel>
          <FormInput
            autoFocus
            margin='dense'
            size='small'
            variant='outlined'
            value={code}
            onChange={handleCodeChange}
            helperText={error}
            error={!!error}
            data-cy='verificationCodeInput'
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant='outlined' data-cy='backButton'>
          Back
        </Button>
        <Button
          variant='contained'
          color='secondary'
          type='submit'
          disabled={code.length !== 6 || !!error || loading}
          data-cy='confirmCodeButton'
        >
          Confirm Code
        </Button>
      </DialogActions>
    </StyledForm>
  )
}
